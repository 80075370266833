<template>

<!-- 


 ____           _      _ _  __   _____         _      _       __    _      
|  _ \         | |    (_|_)/ _| |  __ \       | |    (_)     / _|  | |     
| |_) | ___  __| |_ __ _ _| |_  | |  | | ___  | |     _  ___| |_ __| | ___ 
|  _ < / _ \/ _` | '__| | |  _| | |  | |/ _ \ | |    | |/ _ \  _/ _` |/ _ \
| |_) |  __/ (_| | |  | | | |   | |__| |  __/ | |____| |  __/ || (_| |  __/
|____/ \___|\__,_|_|  |_| |_|   |_____/ \___| |______|_|\___|_| \__,_|\___|
                       _/ |                                                
                      |__/                                                 

Oh hallo daar!

Wat leuk om je hier te zien!

Was je benieuwd naar de code?
Mocht je vragen of opmerkingen hebben mail me dan gerust:
Wesley@bedrijftdeliefde.nu

Laat ons ook even weten dat je deze easteregg gevonden hebt.

Liefs,
Wesley en de rest
Bedrijf de Liefde


-->


  <router-view />
</template>

<style lang="scss">

</style>
