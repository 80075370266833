<template>
  <div 
  @click="pushItem($event)" 
  class="icon" 
  :class="[item.fileType, item.type]" 
  tabindex="0" 
  ref="icon"
  :style="`top: ${top}; left: ${left};`"
  >
    <div class="image"></div>
    <div class="name">
      <span>{{item.name}}</span>
    </div>
  </div>
</template>

<script>
import { ref, reactive, readonly, watch, onMounted } from "vue";
import Modal from "./Modal.vue";
import { inject } from '@vue/runtime-core'

import emitter from "@/services/emitter";

export default {
    props: ["item", "top", "left"],
  setup(props) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    const icon = ref(null);

    const files = inject("files");

    onMounted(() => {
      //     if (document.getElementById(elmnt.id + "header")) {
      //   // if present, the header is where you move the DIV from:
      //   document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      // } else {
      //   // otherwise, move the DIV from anywhere inside the DIV:
      //   elmnt.onmousedown = dragMouseDown;
      // }

      // console.log(props.item)

      // icon.value.style.top = props.item.top+"%";
      // icon.value.style.left = props.item.left+"%";

      // icon.value.onmousedown = dragMouseDown;
    });

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      icon.value.style.top = icon.value.offsetTop - pos2 + "px";
      icon.value.style.left = icon.value.offsetLeft - pos1 + "px";

    //   console.log(
    //       ((icon.value.offsetTop / window.innerHeight) * 100).toFixed(2),
    //       ((icon.value.offsetLeft / window.innerWidth) * 100).toFixed(2)
    //   )
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }


    const modals = inject("modals");

    function pushItem(e){
      
      // console.log(props.item);
      modals.value.push(Object.assign({}, props.item));

      emitter.emit("newObject", {});
      e.stopPropagation();
    }

    const contentOpen = ref(false);

    return {
      icon,
      contentOpen,
      pushItem
    };
  },
};
</script>

<style>
</style>