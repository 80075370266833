<template>
  
    <div id="loginscreen" :class="active ? 'active' : ''">
        <header></header>
        <div class="user">
            <div class="profile-picture"></div>
            <h2>Sarah Elster</h2>
            <div class="password" @click="startTyping" ref="writer">wachtwoord</div>
            <span>Waar komt die bijnaam van Den Bosch eigenlijk vandaan? </span>
        </div>
        <footer>
            <a href="https://bedrijfdeliefde.nu" class="bdl-logo"></a>
            <span>Moer.OS is een project van <a href="https://bedrijfdeliefde.nu" target="_blank" class="bdl-text">Bedrijf de Liefde</a></span>
        </footer>
        <!-- <div class="mos_logo"></div> -->

        <div class="about" :class="aboutOpen ? 'active' : ''" v-if="!active" @click="aboutOpen = !aboutOpen"></div>
        <div class="about-modal" :class="aboutOpen ? 'active' : ''">
            <img src="/image/moeros/mos_logo_black.png">
            <p>
                
                Moerasdraak is een korte speelfilm door Ruben Swart in samenwerking met Cityboost waarin een aantal studenten een diep bewaard geheim van de stad Den Bosch onthullen, waarbij ze zo diep in de geschiedenis van de stad graven dat ze hun eigen levens in gevaar brengen.<br><br>
        
                Welkom op de computer van Sarah Elster, een Bossche studente die met haar projectgroep een documentaire moet maken over de bijnaam van de stad ’s-Hertogenbosch: Moerasdraak.<br><br>

De vraag luidt of er dan echt iets heeft geleefd rondom de stad dat de naam Moerasdraak heeft opgeleverd. Hier vind je alle aanwijzingen die naar een diep bewaard geheim leiden over deze bijnaam.<br><br>

Vanaf <b>29 mei</b> is de mockumentary te zien in de Verkadefabriek!<br><br>


<small><i>Alle content op deze website, hoewel sommige ervan is gebaseerd op de Boscche geschiedenis, is fictief.</i></small>

            </p>
        </div>
    </div>

</template>

<script>
import { inject, onMounted, ref } from '@vue/runtime-core'

import Typewriter from "typewriter-effect/dist/core";


export default {

    setup(){
        const isLoggedIn = inject("isLoggedIn");
        const writer = ref(null);

        const active = ref(false);
        const aboutOpen = ref(false);


        let typewriter = null;

        onMounted(() => {
            //  isLoggedIn.value = true;

        })

       


        const startTyping = () => {

            // console.log("start")


            typewriter = new Typewriter(writer.value, {
                loop: false,
                delay: 75,
                deleteSpeed: 0,
                cursor: "",
            });

            typewriter
            .typeString("M").pauseFor(20).deleteChars(1).typeString("*")
            .typeString("0").pauseFor(20).deleteChars(1).typeString("*")
            .typeString("3").pauseFor(20).deleteChars(1).typeString("*")
            .typeString("r").pauseFor(20).deleteChars(1).typeString("*")
            .typeString("@").pauseFor(20).deleteChars(1).typeString("*")
            .typeString("s").pauseFor(20).deleteChars(1).typeString("*")
            .pauseFor(500)
            .callFunction(() => {
               active.value = true;
            })
            .pauseFor(500)
            .callFunction(() => {
                isLoggedIn.value = true;
                aboutOpen.value = false;
            })
            .start();
        }



        return {
            isLoggedIn,
            startTyping,
            writer,
            active,
            aboutOpen
        }

    }
}
</script>

<style>

</style>