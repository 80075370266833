import { ref, reactive, readonly, watch } from 'vue';

// import RepoOfTransformation from "./repo/RepoOfTransformation"
// import repodata from "./data.json"

import Files from './Files';


export function useManager(app) {

    const isLoggedIn = ref(false);
    app.provide("isLoggedIn", isLoggedIn);

    
    app.provide("files", Files)



    const modals = ref([]);
    app.provide("modals", modals);

    return {}

}