
// import LoveMakers from "@/assets/documents/thelovemakers.jpg"
// const Lorem = require("./documents/lorem.pdf");


const Files = [


    
    {
        name: "MOERASDRAAK - Teaser Trailer (2022)",
        path: "/",
        type: "file",
        fileType: "video",
        top: 8,
        left: 78,
        order: 0,
        content: "x3XnMPs-k34"
    },
    
    {
        name: "MOERASDRAAK - Officiële Trailer (2022)",
        path: "/",
        type: "file",
        fileType: "video",
        top: 8,
        left: 78,
        order: 0,
        content: "Qmx8Z6-cuoM"
    },


    {
        name: "Over_Moerasdraak.jpg",
        path: "/",
        type: "file",
        order: 0,
        fileType: "image",
        content: "/documents/root/Over_Moerasdraak.png"
        
    },

    {
        name: "Plan_Van_Aanpak.jpg",
        path: "/",
        type: "file",
        order: 0,
        fileType: "image",
        content: "/documents/root/Plan_Van_Aanpak.jpg"
        
    },

    {
        name: "POSTER.jpg",
        path: "/Onderzoek/",
        type: "file",
        order: 0,
        fileType: "image",
        content: "/documents/root/POSTER.jpg"
        
    },

    {
        name: "Privé",
        path: "/",
        type: "folder",
        top: 30,
        left: 25,
        order: 0,
        content: [

            {
                name: "Papa.jpg",
                path: "/Privé/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/prive/Papa.jpg"
                
            },

            {
                name: "Papa2.jpg",
                path: "/Privé/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/prive/Papa2.jpg"
                
            },

            {
                name: "Zonnetje.jpg",
                path: "/Privé/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/prive/Zonnetje.jpg"
                
            }
            

        ]
    },



    {
        name: "Onderzoek_stad",
        path: "/",
        type: "folder",
        top: 30,
        left: 25,
        order: 0,
        content: [
            {
                name: "Interviews_Stad",
                path: "/Onderzoek_stad/",
                type: "file",
                fileType: "video",
                top: 8,
                left: 78,
                order: 0,
                content: "8OsoGydvrhA"
            },
            {
                name: "Moerasdraak",
                path: "/Onderzoek_stad/",
                type: "folder",
                top: 30,
                left: 25,
                order: 0,
                content: [


                    {
                        name: "BN De stem.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/bn_de_stem.jpg"
                    },

                    {
                        name: "Bossche Encyclopedie.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/bossche_encyclopedie.jpg"
                    },

                    {
                        name: "Columbus Magazine.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/columbus_magazine.jpg"
                    },

                    {
                        name: "DTV Nieuws.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/dtv_nieuws.jpg"
                    },

                    {
                        name: "Forten.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/forten.jpg"
                    },

                    {
                        name: "Geography.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/geography.jpg"
                    },

                    {
                        name: "In de buurt.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/in_de_buurt.jpg"
                    },

                    {
                        name: "izi Travel.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/izi_travel.jpg"
                    },

                    {
                        name: "Mooiste Routes.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/mooiste_routes.jpg"
                    },

                    {
                        name: "route you.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/route_you.jpg"
                    },

                    {
                        name: "Staatsbosbeheer.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/staatsbosbeheer.jpg"
                    },

                    {
                        name: "Stamboeck.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/stamboeck.jpg"
                    },

                    {
                        name: "Wawona.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/wawona.jpg"
                    },

                    {
                        name: "Wiki_Moerasdraak.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/wiki_moerasdraak.jpg"
                    },

                    {
                        name: "Zuidwaterlinie.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/zuidwaterlinie.jpg"
                    },

                    {
                        name: "Zuidwaterlinie_2.jpg",
                        path: "/Onderzoek_stad/Moerasdraak/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/moerasdraak/zuidwaterlinie_2.jpg"
                    },




                    
                ]
            },
            {
                name: "Nieuws_gemeente",
                path: "/Onderzoek_stad/",
                type: "folder",
                top: 30,
                left: 25,
                order: 0,
                content: [

                    {
                        name: "Nieuwsbericht_huisdieren.png",
                        path: "/Onderzoek_stad/Nieuws_gemeente/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/nieuws_gemeente/nieuwsbericht_huisdieren.jpg"
                    },

                    {
                        name: "Nieuwsbericht_Moerputten.jpg",
                        path: "/Onderzoek_stad/Nieuws_gemeente/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_stad/nieuws_gemeente/nieuwsbericht_moerputten.jpg"
                    },
                    
                ]
            }
        ]
    },


    {
        name: "Onderzoek_geschiedenis",
        path: "/",
        type: "folder",
        top: 30,
        left: 25,
        order: 0,
        content: [

            {
                name: "Stadsarchief.jpg",
                path: "/Onderzoek_geschiedenis/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/onderzoek_geschiedenis/stadsarchief.jpg"
            },

            {
                name: "De lintworm in _s-Hertogenbosch_2.pdf",
                path: "/Onderzoek_geschiedenis/",
                type: "file",
                order: 0,
                fileType: "document",
                content: "/documents/root/onderzoek_geschiedenis/de_lintworm_in_s-Hertogenbosch_2.pdf"
            },


            {
                name: "Documenten Beleg 1629",
                path: "/",
                type: "folder",
                top: 30,
                left: 25,
                order: 0,
                content: [
        
                    {
                        name: "Maurits - Duivelsch Wezen.jpg",
                        path: "/Onderzoek_geschiedenis/Documenten Beleg 1629/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_geschiedenis/documenten_beleg_1629/maurits_-_duivelsch_wezen.jpg"
                    },
        
                    {
                        name: "Tekst Frederik Hendrik.jpg",
                        path: "/Onderzoek_geschiedenis/Documenten Beleg 1629/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_geschiedenis/documenten_beleg_1629/tekst_frederik_hendrik.jpg"
                    },
        
        
                
                ]
            },


            {
                name: "Documenten stadsarchief",
                path: "/",
                type: "folder",
                top: 30,
                left: 25,
                order: 0,
                content: [
        
                    {
                        name: "1889 - Spoorlijn sightings",
                        path: "/Onderzoek_geschiedenis/Documenten stadsarchief/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_geschiedenis/documenten_stadsarchief/1889_-_Spoorlijn_sightings.jpg"
                    },
        
                    {
                        name: "1943 - Soldaten vermissingen.jpg",
                        path: "/Onderzoek_geschiedenis/Documenten stadsarchief/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_geschiedenis/documenten_stadsarchief/1943_-_Soldaten_vermissingen.jpg"
                    },

                    {
                        name: "1995 - Jheronimus Bosch.jpg",
                        path: "/Onderzoek_geschiedenis/Documenten stadsarchief/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_geschiedenis/documenten_stadsarchief/1995_-_Jheronimus_Bosch.jpg"
                    },
        
                    {
                        name: "Foto Creature.jpg",
                        path: "/Onderzoek_geschiedenis/Documenten stadsarchief/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_geschiedenis/documenten_stadsarchief/Foto_Creature.jpg"
                    },
        
        
                
                ]
            },


            {
                name: "Smithsonian",
                path: "/Onderzoek_geschiedenis/",
                type: "folder",
                top: 30,
                left: 25,
                order: 0,
                content: [
        
                    {
                        name: "Kleed.jpg",
                        path: "/Onderzoek_geschiedenis/Smithsonian/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_geschiedenis/smithsonian/kleed.jpg"
                    },
        
                    {
                        name: "Muurkleed.jpg",
                        path: "/Onderzoek_geschiedenis/Smithsonian/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_geschiedenis/smithsonian/muurkleed.jpg"
                    },

                    {
                        name: "OudeKunst.jpg",
                        path: "/Onderzoek_geschiedenis/Smithsonian/",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/onderzoek_geschiedenis/smithsonian/oudekunst.jpg"
                    },       
        
                
                ]
            },






            {
                name: "Rijksmuseum",
                path: "/Onderzoek_geschiedenis/",
                type: "folder",
                top: 30,
                left: 25,
                order: 0,
                content: [



                    {
                        name: "Beleg van 1629",
                        path: "/Onderzoek_geschiedenis/Rijksmuseum/",
                        type: "folder",
                        top: 30,
                        left: 25,
                        order: 0,
                        content: [

                            {
                                name: "Frederik Hendrik en Ernst Casimir bij het Beleg - Pauwels van Hillegaert.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/frederik_hendrik_en_ernst_casimir_bij_het_beleg_-_pauwels_van_hillegaert.jpg"
                            },
        
                            {
                                name: "Kaart Beleg 1629 - 1.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/kaart_beleg_1629_-_1.jpg"
                            },  

                            {
                                name: "Beleg en inname.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/beleg_en_inname.jpg"
                            }, 

                            {
                                name: "Adriaen Pieters van de Venne - Kaart Beleg van 1629 (moeras).jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/adriaen_pieters_van_de_venne_-_kaart_beleg_van_1629_moeras.jpg"
                            }, 

                            {
                                name: "Print(1).jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/print_1.jpg"
                            },

                            {
                                name: "Kaart.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/kaart.jpg"
                            },
        
                            {
                                name: "Print.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/print.jpg"
                            },  

                            {
                                name: "Prins Frederik Hendrik - Portret 1630.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/prins_frederik_hendrik_bij_het_beleg.jpg"
                            }, 

                            {
                                name: "Pieter de Neyn - Beleg van 1629.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/prins_frederik_hendrik_-_portret_1630.jpg"
                            },

                            {
                                name: "Maurits van Nassau_2.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/pieter_de_neyn_-_beleg_van_1629.jpg"
                            },
        
                            {
                                name: "Draak.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/maurits_van_nassau_2.jpg"
                            },  

                            {
                                name: "Kaart Belegering _s-Hertogenbosch - 1629.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/kaart_belegering__s-hertogenbosch_-_1629.jpg"
                            }, 

                            {
                                name: "Maurits van Nassau.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Beleg van 1629",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/beleg_van_1629/maurits_van_nassau.jpg"
                            },

                         
                
                                  
                
                        
                        ]
                    },


                    {
                        name: "Draken en Lintwormen",
                        path: "/Onderzoek_geschiedenis/Rijksmuseum/",
                        type: "folder",
                        top: 30,
                        left: 25,
                        order: 0,
                        content: [

                            {
                                name: "Draak in moeras buiten Rome.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Draken en Lintwormen",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/draken_en_lintwormen/draak_in_moeras_buiten_rome.jpg"
                            },
        
                            {
                                name: "Draak.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Draken en Lintwormen",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/draken_en_lintwormen/draak.jpg"
                            },  

                            {
                                name: "Lintworm.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Draken en Lintwormen",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/draken_en_lintwormen/lintworm.jpg"
                            }, 

                            {
                                name: "Lintworm(1).jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Draken en Lintwormen",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/draken_en_lintwormen/lintworm_1.jpg"
                            },
        
                             
                
                                  
                
                        
                        ]
                    },


                    {
                        name: "Jheronimus Bosch",
                        path: "/Onderzoek_geschiedenis/Rijksmuseum/",
                        type: "folder",
                        top: 30,
                        left: 25,
                        order: 0,
                        content: [

                            {
                                name: "Hieronimo Boschio.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Jheronimus Bosch",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/jheronimus_bosch/hieronimo_boschio.jpg"
                            },
        
                            {
                                name: "Kreupele mensen.jpg",
                                path: "/Onderzoek_geschiedenis/Rijksmuseum/Jheronimus Bosch",
                                type: "file",
                                order: 0,
                                fileType: "image",
                                content: "/documents/root/onderzoek_geschiedenis/rijksmuseum/jheronimus_bosch/kreupele_mensen.jpg"
                            },  
                
                                  
                
                        
                        ]
                    }
        
                          
        
                
                ]
            }



            



        ]
    },




    {
        name: "MOERASDRAAK",
        path: "/",
        type: "folder-lock",
        password: "M03r@s",
        pwhint: "Zelfde wachtwoord als waar ik mee inlog.",
        top: 30,
        left: 25,
        order: 0,
        content: [

            {
                name: "Archief_Moerputten_03-10-1993.mp4",
                path: "/",
                type: "file",
                fileType: "video",
                top: 8,
                left: 78,
                order: 0,
                content: "tZez8oPWkvM"
            },

            {
                name: "Moerasdraak?.mp4",
                path: "/",
                type: "file",
                fileType: "video",
                top: 8,
                left: 78,
                order: 0,
                content: "Jn3R1Mxmr7Y"
            },

            {
                name: "Behind the Scenes",
                path: "/MOERASDRAAK/",
                type: "folder-lock",
                password: "belegvan1629",
                pwhint:"Het moment in de tijd dat Den Bosch werd ingenomen door Frederik Hendrik van Oranje. (Alles kleine letters, geen spaties)",
                top: 30,
                left: 25,
                order: 0,
                content: [ 


                    {
                        name: "Concept_Art_1.jpg",
                        path: "/MOERASDRAAK/Behind the Scenes",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/moerasdraak/behind_the_scenes/concept_art_1.jpg"
                    },

                    {
                        name: "First_Drawing_Characterssheet_2014.jpg",
                        path: "/MOERASDRAAK/Behind the Scenes",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/moerasdraak/behind_the_scenes/first_drawing_characterssheet_2014.jpg"
                    },  


                    {
                        name: "Laatste_Draaidag.jpg",
                        path: "/MOERASDRAAK/Behind the Scenes",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/moerasdraak/behind_the_scenes/laatste_draaidag.jpg"
                    },

                    {
                        name: "Moerasdraak_Concept.jpg",
                        path: "/MOERASDRAAK/Behind the Scenes",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/moerasdraak/behind_the_scenes/moerasdraak_concept.jpg"
                    },  

                    {
                        name: "Moerasdraak_In_The_Making.jpg",
                        path: "/MOERASDRAAK/Behind the Scenes",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/moerasdraak/behind_the_scenes/moerasdraak_in_the_making.jpg"
                    },

                    {
                        name: "Moerasdraak_Storyboard_Scene41.jpg",
                        path: "/MOERASDRAAK/Behind the Scenes",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/moerasdraak/behind_the_scenes/moerasdraak_storyboard_scene41.jpg"
                    },  

                    {
                        name: "Pre_Vis_SDS0001.png",
                        path: "/MOERASDRAAK/Behind the Scenes",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/moerasdraak/behind_the_scenes/pre_vis_sds0001.jpg"
                    },

                    {
                        name: "Sarah_ConceptArt_PleunWilting.png",
                        path: "/MOERASDRAAK/Behind the Scenes",
                        type: "file",
                        order: 0,
                        fileType: "image",
                        content: "/documents/root/moerasdraak/behind_the_scenes/sarah_conceptart_pleunwilting.jpg"
                    },  


                ]
            }


        ]

    },


    {
        name: "Makers",
        path: "/",
        type: "folder",
        top: 30,
        left: 25,
        order: 0,
        content: [
            {
                name: "AlleMakers.pdf",
                path: "/Onderzoek_geschiedenis/",
                type: "file",
                order: 0,
                fileType: "document",
                content: "/documents/root/makers/DeCrew.pdf"
            },

            
            {
                name: "AlonIsraeli.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/AlonIsraeli.jpg"
            },

            {
                name: "ChristiaanUnck.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/ChristiaanUnck.jpg"
            },

            {
                name: "Chris_de_Groot.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/Chris_de_Groot.jpg"
            },

            {
                name: "DaniSteevensz.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/DaniSteevensz.jpg"
            },


            {
                name: "FlorisKimpel.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/FlorisKimpel.jpg"
            },

            {
                name: "GijsDeJonge.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/GijsDeJonge.jpg"
            },

            {
                name: "JamilladeMoor.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/JamilladeMoor.jpg"
            },

            {
                name: "JuliaVerheijen.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/JuliaVerheijen.jpg"
            },

            {
                name: "JurrianMarx.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/JurrianMarx.jpg"
            },

            {
                name: "KarlijnDeKok.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/KarlijnDeKok.jpg"
            },

            {
                name: "KellyPapavoine.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/KellyPapavoine.jpg"
            },

            {
                name: "KoenRaedts.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/koen_raedts.jpg"
            },

            {
                name: "MarijnPoelmans.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/MarijnPoelmans.jpg"
            },

            {
                name: "MartijmScherff.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/MartijmScherff.jpg"
            },

            {
                name: "MuhammedCaglar.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/MuhammedCaglar.jpg"
            },

            {
                name: "NielsReuvekamp.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/NielsReuvekamp.jpg"
            },

            {
                name: "NiniPronk.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/NiniPronk.jpg"
            },

            {
                name: "PeggyLoch.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/PeggyLoch.jpg"
            },

            {
                name: "PleunWilting.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/PleunWilting.jpg"
            },

            {
                name: "RafkeBrils.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/RafkeBrils.jpg"
            },

            {
                name: "RickLichtenberg.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/RickLichtenberg.jpg"
            },

            {
                name: "RoosGroenen.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/RoosGroenen.jpg"
            },

            {
                name: "RubenSwart.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/RubenSwart.jpg"
            },

            {
                name: "SanneBoersma.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/SanneBoersma.jpg"
            },

            {
                name: "TeunHeijligers.jpg",
                path: "/Makers/",
                type: "file",
                order: 0,
                fileType: "image",
                content: "/documents/root/makers/TeunHeijligers.jpg"
            }
            
        ]
    },



    {
        name: "Docu_Draft",
        path: "/",
        type: "folder",
        top: 30,
        left: 25,
        order: 0,
        content: [
            {
                name: "Slag_van_Leckerbeetje.mp4",
                path: "/",
                type: "file",
                fileType: "video",
                top: 8,
                left: 78,
                order: 0,
                content: "IgD0yjbVPi8"
            },

            {
                name: "Beleg_van _1629.mp4",
                path: "/",
                type: "file",
                fileType: "video",
                top: 8,
                left: 78,
                order: 0,
                content: "xtUnF0UsP-c"
            }
        ]
    }





    


];







export default Files;