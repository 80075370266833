<template>
  <div class="modal" ref="modal" :style="`top: ${topM || top}; left: ${leftM || left};`">
    <header>
      <span class="filename">~/Desktop{{ path }}{{ name }}</span>
      <button @click="$emit('close-modal', $event)" class="close-btn"></button>
    </header>
    <div class="content" :class="[type, fileType]">
      <template v-if="type == 'folder'">
        <div class="icon-grid in-folder">
          <icon
            v-for="(item, index) in content"
            :key="index"
            :item="item"
          ></icon>
        </div>
      </template>

      <template v-if="type == 'folder-lock'">
        <!-- Jij valsspeler. -->
        <div class="lock-overlay" v-if="!passwordCorrect">
          <span>VERBODEN TOEGANG</span>
          <input
            type="password"
            placeholder="Wachtwoord"
            @change="checkPassword"
            autocomplete="off"
          />
          <small class="pw-hint">{{pwhint}}</small>
          <small v-if="passwordIncorrect">INCORRECT WACHTWOORD</small>
        </div>
        <div class="icon-grid in-folder">
          <icon
            v-for="(item, index) in content"
            :key="index"
            :item="item"
          ></icon>
        </div>
      </template>

      <template v-if="fileType == 'image'">
        <div
          class="image"
          :style="`background-image: url(${content})`"
          @click="openViewer(content)"
        ></div>
      </template>

      <template v-if="fileType == 'document'">
        <div class="document">
          <!-- <iframe :src="content"></iframe> -->

          <pdf-viewer :docPath="content"></pdf-viewer>
        </div>
      </template>


      <template v-if="fileType == 'video'">
        <div class="video youtube">
          <div class="ratio-16-9">
            <iframe :src="`https://www.youtube.com/embed/${content}`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, reactive, readonly, watch, onMounted } from "vue";
import { inject } from "@vue/runtime-core";

import { api as viewerApi } from "v-viewer";
// import VuePdfEmbed from 'vue-pdf-embed'
// import pdf from 'pdfvuer'

import PdfViewer from "../pdfviewer.vue";

export default {
  props: [
    "name",
    "type",
    "fileType",
    "content",
    "top",
    "left",
    "index",
    "password",
    "pwhint",
    "path",
  ],
  components: {
    PdfViewer
  },
  setup(props) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    const modal = ref(null);

    const modals = inject("modals");

    onMounted(() => {
      //     if (document.getElementById(elmnt.id + "header")) {
      //   // if present, the header is where you move the DIV from:
      //   document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      // } else {
      //   // otherwise, move the DIV from anywhere inside the DIV:
      //   elmnt.onmousedown = dragMouseDown;
      // }

      // console.log(props);

      // modal.value.style.top = props.content?.top + "%" || "10%";
      // modal.value.style.left = props.content?.left + "%" || "10%";

 if(window.innerWidth > 768 ){
      modals.value[props.index].top = 0;
      modals.value[props.index].left = 0;

      modals.value[props.index].top =  modals.value[props.index].top  + generateRandom(25, window.innerHeight*.35)+ "px";
      modals.value[props.index].left = modals.value[props.index].left + generateRandom(25, window.innerWidth*.35) + "px";
 }

      modal.value.children[0].onmousedown = dragMouseDown;

      window.addEventListener("resize", resize);
      
    });

    function generateRandom(min = 0, max = 100) {

    // find diff
    let difference = max - min;

    // generate random number 
    let rand = Math.random();

    // multiply with difference 
    rand = Math.floor( rand * difference);

    // add with min value 
    rand = rand + min;

    return rand;
}

    const topM = ref(null);
    const leftM = ref(null);

    const resize = () => {

      if(modal.value == null){
        return;
      }

      if(window.innerWidth < 768){
        topM.value = 0;
        leftM.value = 0;
      }
      else {
        topM.value = null;
        leftM.value = null;
      }


      if(window.innerWidth < 768 ){
        modal.value.removeAttribute("style")
      }

    }




    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      // modal.value.style.top = modal.value.offsetTop - pos2 + "px";
      // modal.value.style.left = modal.value.offsetLeft - pos1 + "px";

      modals.value[props.index].top = modal.value.offsetTop - pos2 + "px";
      modals.value[props.index].left = modal.value.offsetLeft - pos1 + "px";

      //   console.log(
      //       ((modal.value.offsetTop / window.innerHeight) * 100).toFixed(2),
      //       ((modal.value.offsetLeft / window.innerWidth) * 100).toFixed(2)
      //   )
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }

    const passwordIncorrect = ref(false);
    const passwordCorrect = ref(false);

    function checkPassword(e) {
      let value = e.target.value;
      e.target.value = "";

      if (value == props.password) {
        passwordCorrect.value = true;
      } else {
        passwordIncorrect.value = true;
      }
    }

    function openViewer(content) {
      // console.log(content);

      const $viewer = viewerApi({
        options: {
          inline: true,
          button: true,
          navbar: false,
          title: false,
          toolbar: false,
          tooltip: false,
          movable: true,
          zoomable: true,
          rotatable: false,
          scalable: true,
          transition: true,
          fullscreen: false,
          keyboard: false
        },
        images: [content],
      });
    }

    return {
      modal,
      checkPassword,
      passwordCorrect,
      passwordIncorrect,
      openViewer,
      topM,
      leftM
    };
  },
};
</script>

<style>
</style>