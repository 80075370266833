<template>
  
  <div id="desktop-wrap">

    <login-screen v-if="!isLoggedIn"></login-screen>
    <desktop></desktop>

  </div>


</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import LoginScreen from "@/components/LoginScreen.vue";
import Desktop from "@/components/Desktop.vue";
import { inject } from '@vue/runtime-core';


export default {
  name: "Home",
  components: {
    LoginScreen,
    Desktop
  },
  setup(){


    const isLoggedIn = inject("isLoggedIn");



    return {
      isLoggedIn
    }

  }
};
</script>
