<template>
  <div class="pdf-viewer">
    <div class="toolbar">
      <div class="zoom">
        <button @click="zoomOut" class="out" :disabled="pdfScale <= .6"></button>
        <button @click="zoomIn" class="in" :disabled="pdfScale >= 1.5"></button>
      </div>

      <div class="pages" v-if="numPages > 1">
        
        <button @click="pageDown" :disabled="pageNumber <= 1" class="down"></button>
        <span>{{ pageNumber }} / {{ numPages }} </span>
        
        <button @click="pageUp" :disabled="pageNumber == numPages" class="up"></button>
        
      </div>
    </div>
    <div class="canvas-wrap">
      <canvas class="the-canvas" ref="cnv"></canvas>
    </div>
  </div>
</template>

<script>
// import pdfjsLib from "pdfjs-dist/legacy/build/pdf";
const pdfjsLib = require("pdfjs-dist/legacy/build/pdf");
import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";
// import "pdfjs-dist/web/pdf_viewer.css";
import { onMounted, ref } from "@vue/runtime-core";
import { render } from "@vue/runtime-dom";

// pdfjsLib.GlobalWorkerOptions.workerSrc =
//   "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js"

export default {
  name: "PdfViewer",
  props: { docPath: String },
  setup(props) {
    const pdfScale = ref(1);
    const pdfViewport = ref(null);

    const numPages = ref(0);
    const pageNumber = ref(1);
    const cnv = ref(null);

    let _PDF = {};
    let _PDF_PAGE = {};
    let _PDF_VIEWPORT = {};

    onMounted(() => {
      var loadingTask = pdfjsLib.getDocument(props.docPath);
      loadingTask.promise.then(
        function (pdf) {
          console.log("PDF loaded");

          _PDF = pdf;

          numPages.value = pdf._pdfInfo.numPages;

          _PDF.getPage(pageNumber.value).then(function (page) {
            console.log("Page loaded");

            _PDF_PAGE = page;
            renderPdf();
          });
        },
        function (reason) {
          // PDF loading error
          console.error(reason);
        }
      );
    });

    const renderPdf = () => {
      // var viewport = page.getViewport({ scale: pdfScale.value });
      _PDF_VIEWPORT = _PDF_PAGE.getViewport({ scale: pdfScale.value });

      // Prepare canvas using PDF page dimensions
      var canvas = cnv.value;
      var context = cnv.value.getContext("2d");
      canvas.height = _PDF_VIEWPORT.height;
      canvas.width = _PDF_VIEWPORT.width;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: context,
        viewport: _PDF_VIEWPORT,
      };

      var renderTask = _PDF_PAGE.render(renderContext);
      renderTask.promise.then(function () {
        console.log("Page rendered");
      });
    };

    const zoomIn = () => {
      
      if(pdfScale.value > 1.5) return;
      pdfScale.value *= 1.25;
      


      renderPdf();
    };

    const zoomOut = () => {
       if(pdfScale.value <= .6) return;
      pdfScale.value *= 0.75;
      renderPdf();
    };

    const pageUp = () => {
      if (pageNumber.value + 1 > numPages.value) return;
      pageNumber.value += 1;

      _PDF.getPage(pageNumber.value).then(function (page) {
        console.log("Page loaded");

        _PDF_PAGE = page;
        renderPdf();
      });
    };

    const pageDown = () => {
      if (pageNumber.value - 1 <= 0) return;
      pageNumber.value -= 1;

      _PDF.getPage(pageNumber.value).then(function (page) {
        console.log("Page loaded");

        _PDF_PAGE = page;
        renderPdf();
      });
    };

    return {
      zoomIn,
      zoomOut,
      pageUp,
      pageDown,
  pdfScale,
      numPages,
      pageNumber,
      cnv,
    };
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  padding: .25rem;
  position: sticky;
  top: 0;

  background-color: rgba(190, 190, 190, 0.95);

  display: flex;
  justify-content: space-between;
  align-items: center;

  .pages {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: .5rem;
    margin-right: .5rem;
    height: 100%;
  }

  button {
    padding: .25rem;
    margin: .25rem;

    width: 30px;
    height: 30px;

    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;

    cursor: pointer;

    border: none;

    
  }

  .up {
      background-image: url("/image/moeros/icon_page_down.svg");
    }

    .down {
      background-image: url("/image/moeros/icon_page_up.svg");
    }

    .in {
      background-image: url("/image/moeros/icon_zoom_in.svg");
    }

    .out {
      background-image: url("/image/moeros/icon_zoom_out.svg");
    }


}




canvas {
  /* margin: 0 auto; */

  /* max-width: 100%; */
  margin: 1rem;

  display: block;
  margin: auto auto;
}

.canvas-wrap {
  max-width: 100%;
  margin: 1rem;
  overflow: auto;
}
</style>