<template>
  <div id="desktop" :class="isLoggedIn ? 'active' : ''">
    <!-- <pre>
      {{ modals }}
    </pre> -->

    <div class="icon-grid">
      <icon
        v-for="(item, index) in files"
        :index="index"
        :top="item.top"
        :left="item.left"
        :key="index"
        :item="item"
      ></icon>
    </div>

    <div class="modal-wrap moved"></div>
    <div class="modal-wrap">
      <modal
        :name="item.name"
        :class="item.type"
        :type="item.type"
        :fileType="item.fileType"
        :content="item.content"
        data-valsspeler="Je speelt vals!"
        :password="item.password"
        :pwhint="item.pwhint"
        :top="item.top"
        :left="item.left"
        :index="index"
        :path="item.path"
        v-for="(item, index) in modals"
        :key="index"
        :data-last="index == modals.length - 1"
        v-on:close-modal="closeModal(index, $event)"
        @click="bringToFront($event)"
      ></modal>
    </div>

    <div
      class="hint"
      title="Je hebt een dragon egg gevonden! Liefs, Bedrijf de Liefde"
      data-valsspeler="Als je dit leest heb je valsgespeeld."
      @click="alertHint"
    ></div>

    <!-- <div class="mos_logo"></div> -->

    <div class="taskbar" :class="isLoggedIn ? 'active' : ''">
      <div class="start-btn" @click="isLoggedIn = false"></div>

      <span>Moer.OS</span>
    </div>
  </div>
</template>

<script>
import { inject, onMounted } from "@vue/runtime-core";
import Icon from "./DesktopElements/Icon.vue";

import emitter from "@/services/emitter";

export default {
  components: { Icon },

  setup() {
    const files = inject("files");
    const modals = inject("modals");

    const closeModal = (index, e) => {
      modals.value.splice(index, 1);
      e.stopPropagation();
    };

    onMounted(() => {
      emitter.on("newObject", movePreviousModal);
    });

    function movePreviousModal() {
      let wrap = document.querySelector(
        ".modal-wrap:not(.modal-wrap.moved)"
      );
      
      if (wrap == null) return;

      let nodeMoved = document.querySelector(".modal-wrap");
      let node = wrap.querySelector(".modal");

      
      if(node == null) return;
      nodeMoved.appendChild(node);
      

    }

    const bringToFront = (e) => {
      let isInput = e.target.nodeName == "INPUT";
      let isClose = e.target;

      let node = e.target.closest(".modal");
      let nodeMoved = document.querySelector(".modal-wrap.moved");
      let front = nodeMoved.appendChild(node);

      if (isInput) {
        front.querySelector("input").focus();
      }
    };

    function alertHint() {
      alert("Hoe wordt de Gouden Draak ook wel eens genoemd?");
    }

    const isLoggedIn = inject("isLoggedIn");

    return {
      files,
      modals,
      closeModal,
      bringToFront,
      alertHint,
      isLoggedIn,
    };
  },
};
</script>

<style>
</style>