import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import style from "@/styles/index.scss"

import { useManager } from "@/assets/Manager";

import Icon from "@/components/DesktopElements/Icon.vue";
import Modal from "@/components/DesktopElements/Modal.vue";

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import VuePdf from 'vue3-pdfjs'



const app = createApp(App).use(router).use(VuePdf).use(VueViewer)
const manager = useManager(app);

app.component("icon", Icon);
app.component("modal", Modal);


app.mount("#app");
